import { Draft, OptionValue, OrderItem, ProductOption } from '@/generated/graphql/graphql'
import Honeybadger from '@/modules/core/services/honeybadger.service'
import { CatalogProduct } from '@/modules/view-model/views/product-landing/builder/view-model'
import { Product as AlgoliaProduct } from '@/services/algolia'
import { Product as ContentfulProduct } from '@/services/storefront/constants'
import Cookie from 'cookie'
import { Maybe } from 'graphql/jsutils/Maybe'
import { IncomingHttpHeaders } from 'http'
import { GetServerSidePropsContext } from 'next'
import { useState } from 'react'
import { FORMATS_CODE_MAP } from './constants'

const CUSTOMIZER_AUTH_COOKIE_PATTERN = /^S?SESS/
const AIO_CATEGORY_NAME = 'all-in-one'

export async function callHoneybadger(err: string) {
  const { default: hb } = await import('../../modules/core/services/honeybadger.service')
  hb.notify(err)
}

export function stringifyError(target: Error | undefined): string {
  if (typeof target === 'undefined') return ''
  return JSON.stringify(target, Object.getOwnPropertyNames(target))
}

export type OnEnterCallback = (arg0: unknown) => void

export type OnEnter = (callback: OnEnterCallback) => (event: unknown) => void

const PAPER_HOST = process.env.APP_PAPER_HOST || ''
const APP_BASEPATH = process.env.APP_BASEPATH || ''
const APP_PRODUCT_PATH = process.env.APP_PRODUCT_PATH
export const exclusionMap = ['RSVP Cards', 'Enclosure Cards', 'Envelope Liners']

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onEnter: OnEnter = (callback) => (event: any) => {
  if (event.key === 'Enter') {
    callback(event)
  }
}

export function navigateToCart() {
  const sourcePath = String(process.env.APP_BASEPATH)
  window.location.assign(process.env.APP_PAPER_HOST + sourcePath + '/cart')
}

export const getProductRelativeUrl = (url = '') => {
  return url.replace(`${PAPER_HOST}${APP_BASEPATH}`, '').split('?')[0]
}

const parseQueryParams = (querystring: string) => {
  const params = new URLSearchParams(querystring)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const obj: { [key: string]: any } = {}
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key)
    } else {
      obj[key] = params.get(key)
    }
  }
  return obj
}

export const legacyIsAioAlgoliaProduct = (product: CatalogProduct) => {
  const { categoryName } = product
  const lowercaseCategoryNames = Array.isArray(categoryName)
    ? categoryName.map((category) => category.toLowerCase())
    : categoryName
    ? [categoryName.toLowerCase()]
    : []
  return lowercaseCategoryNames.some((category) => category.includes(AIO_CATEGORY_NAME))
}

const productFormatTranslate = (format: string) => {
  return format?.charAt(0).toUpperCase() + format?.slice(1)
}

export const getProductFormats = (product: AlgoliaProduct) => {
  const { format } = product
  if (Array.isArray(format)) {
    return [...new Set(format.map((str) => productFormatTranslate(str)))].join(', ')
  } else if (format) {
    return productFormatTranslate(format)
  } else {
    return ''
  }
}

export const getProductFormatBC = (productCode: OrderItem['variation']['productCode']): string => {
  return FORMATS_CODE_MAP[productCode] ?? 'standard'
}

export const isAioProduct = (product: ContentfulProduct) => {
  const { type } = product
  return type.sku === 'AIO'
}

export const getProductDetailRelativeUrl = (slug = '') => {
  return `${APP_PRODUCT_PATH}/${slug.replace(`${PAPER_HOST}${APP_BASEPATH}`, '').split('?')[0]}`
}

export const getProductQueryParamsUrl = (url = '') => {
  const queryParamsString = url.replace(`${PAPER_HOST}${APP_BASEPATH}`, '').split('?')[1]
  return parseQueryParams(queryParamsString)
}

export const getProductDetailUrlStructure = () => `${APP_PRODUCT_PATH}/[slug]`

export const getCategoryUrlStructure = (isChildCategory: boolean, name = '') => {
  return isChildCategory && !exclusionMap.includes(name) ? '/[category]/[subcategory]' : '/[category]'
}

export const getCategoryUrlStructureWW = (isChildCategory: boolean, name = '') => {
  return isChildCategory && !exclusionMap.includes(name) ? '/[subcategory]' : '/[category]'
}

export const getCategoryUrlStructureFromUrl = (url: string) => {
  if (!url) return ''
  const relativeUrl = getProductRelativeUrl(url)
  const categoriesPath = relativeUrl ? relativeUrl.split('/').filter(Boolean) : []
  const isChildCategory = categoriesPath.length > 1
  return getCategoryUrlStructure(isChildCategory)
}

/**
 * Builds the relative subcategory url.
 * @param productType : The product type returned from Storefront API
 * @param productCategory : The product category returned from Storefront API
 * @returns : The subcategory relative url. Ex. "/invitations/wedding" or "/rsvp-cards"
 */

export const isNotNull = <T>(item: Maybe<T>): item is T => !!item

/*
  This checks if the cookie USER_ID exists, if so returns nothing, if it doesn't, it gets created and returns the value in the gp_anon_id cookie.
 */
export const handleSegmentIdentify = () => {
  if (!document) {
    return
  }
  const allCookies = document.cookie
  const userId = getCookieByName(allCookies, 'USER_ID')
  if (userId) {
    return
  }
  const uid = getCookieByName(allCookies, 'gp_anon_id')
  if (!uid) {
    return
  }
  return uid
}

export const getCookieByName = (cookiesString: string, cookieName: string) => {
  const cookies = Cookie.parse(cookiesString)
  return cookies[cookieName] ?? ''
}

export const getCookieByPattern = (cookiesString: string, pattern: RegExp = CUSTOMIZER_AUTH_COOKIE_PATTERN) => {
  const cookies = Cookie.parse(cookiesString)
  const customizerAuthCookieKey = Object.keys(cookies).find((key) => pattern.test(key))
  return customizerAuthCookieKey
}

export const serializeCookie = (cookie: Record<string, string>): string => {
  if (!cookie) {
    throw new Error('No cookie provided')
  }
  return Object.entries(cookie).reduce((acc, [key, value]) => {
    if (!value) return acc
    return acc + key + '=' + encodeURIComponent(value) + ';'
  }, '')
}

export const buildRequest = (ctx: GetServerSidePropsContext) => {
  const {
    query,
    req: { cookies = {} },
  } = ctx

  const customizerAuthCookieKey = Object.keys(cookies).find((key) => CUSTOMIZER_AUTH_COOKIE_PATTERN.test(key))
  const customCookies = {
    UC: cookies.UC || '',
    USER_ID: cookies.USER_ID || '',
    USER_TIPO: cookies.USER_TIPO || '',
    USER_INFO: cookies.USER_INFO || '',
    ...(customizerAuthCookieKey ? { [customizerAuthCookieKey]: cookies[customizerAuthCookieKey] } : {}),
  } as Record<string, string>

  const token =
    cookies?.UC && cookies?.USER_ID && cookies?.USER_TIPO
      ? `UC=${cookies?.UC};USER_ID=${cookies?.USER_ID};USER_TIPO=${cookies?.USER_TIPO}`
      : ''

  const headers: IncomingHttpHeaders = {
    token,
    cookie: serializeCookie(customCookies),
    'user-agent': ctx.req.headers['user-agent'] || undefined,
    'x-user-id': cookies.USER_ID || undefined,
    'x-user-tipo': cookies.USER_TIPO || undefined,
    'x-user-uc': cookies.UC || undefined,
  }

  return {
    query,
    headers,
  }
}

export const removeQueryParamsFromPathUrl = (pathUrl: string | undefined) => `${pathUrl}`.split('?')[0]

const isPath = (path: string) => window?.location?.pathname.includes(path)

const refresh = () => window.location.reload()
const redirect = (to: string) => window.location.assign(to)

export const basePath = process.env.APP_BASEPATH

export const handleRefreshPage = () => {
  const pathsToRefresh = [`${basePath}/cart`, `${basePath}/checkout`, `${basePath}/your-drafts`]

  if (pathsToRefresh.some(isPath)) {
    refresh()
    return true
  }
  return false
}

export const handlePageLogout = () => {
  const pathsToRedirect = [
    `${basePath}/canvas`,
    `/review`,
    `${basePath}/checkout`,
    `${basePath}/your-orders`,
    `${basePath}/your-drafts`,
  ]
  const pathsToRefresh = [`${basePath}/cart`]

  if (pathsToRedirect.some(isPath)) {
    redirect(`${basePath}/products`)
  } else if (pathsToRefresh.some(isPath)) {
    refresh()
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeUndefinedProps = (obj: any) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (value === undefined) delete obj[key]
  })

  return obj
}

export const getClientHeaders = (): IncomingHttpHeaders => {
  if (typeof window !== 'undefined') {
    return {
      cookie: document.cookie,
      'user-agent': window.navigator.userAgent,
    }
  }

  return {}
}

export function throwError(error: unknown, message: string) {
  let errorMessage = message

  if (error instanceof Error) {
    errorMessage = error.message
  }

  throw new Error(errorMessage)
}

/* Image helpers */
type ImageDimensions = {
  naturalWidth: number
  naturalHeight: number
}

export const buildSrc = (path: string) => `${process.env.APP_MEDIA_API}/images/fetch/${path}`

export const appendRefetch = (path: string) => `${path}?refetch=true`

export const setImageHost = (path: string) =>
  path.includes(String(process.env.APP_IMAGE_HOST)) ? path : `${process.env.APP_IMAGE_HOST}/master/${path}`

export const useImageSizes = () => {
  const [sizes, set] = useState({ width: 0, height: 0 })

  const setSizes = (e: ImageDimensions) => set({ width: e.naturalWidth, height: e.naturalHeight })

  return [sizes, setSizes] as const
}

export const sortByPaperType = (paperTypes: ProductOption[]) => {
  if (paperTypes.length < 2) {
    return paperTypes
  }

  const reordered = []

  reordered.push(paperTypes.filter((v) => v.name === 'Signature').pop())
  reordered.push(paperTypes.filter((v) => v.name === 'Pearlescent').pop())
  reordered.push(paperTypes.filter((v) => v.name === 'Recycled').pop())

  return reordered.filter((v) => !!v) as ProductOption[]
}

/* Option values */

export const getOptionValueName = (optionValues: OptionValue[], optionId: string): string | undefined =>
  optionValues?.find((optionValue: OptionValue) => optionValue['optionId'] === optionId)?.name

export const handleZendeskOnError = (error: unknown, location?: string) => {
  if (error instanceof Error) {
    const isZendeskWidgetLoaded = !!window.zE
    const widgetType = window.zE?.widget
    const message = 'Zendesk widget failed to load'
    Honeybadger.notify(error, message, {
      context: {
        isZendeskWidgetLoaded,
        widgetType,
        location,
      },
    })
  }
}

export function getZendeskLauncher() {
  return document.getElementById('zendeskLauncher')
}

export function updateZendeskTopPosition(updateOffset: boolean, topOffset: number) {
  const screenHeight = window.innerHeight
  const zendeskLauncher = getZendeskLauncher()

  if (!zendeskLauncher) return

  const offset = screenHeight - topOffset

  if (updateOffset) {
    zendeskLauncher.style.top = `${offset}px`
  } else {
    zendeskLauncher.style.top = ''
  }
}
