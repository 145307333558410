import { Experiment } from './flipper.types'

/**
 * This Experiments does...
 * RECORDED =
 * NOT RECORDED =
 */
export const fullStoryExisting: Experiment = {
  name: 'Full Story Existing',
  routes: /\[...slug\]/i,
  id: String(process.env.APP_FULLSTORY_EXISTING_EXPERIMENT_ID),
  names: {
    control: String(process.env.APP_FULLSTORY_EXISTING_RECORDED_ID),
    variant1: String(process.env.APP_FULLSTORY_EXISTING_NOTRECORDED_ID),
  },
  identifier: 'member',
  tenant: 'tk',
  firstTrigger: true,
}
/**
 * This Experiments does...
 * RECORDED =
 * NOT RECORDED =
 */
export const fullStoryNew: Experiment = {
  name: 'Full Story New',
  routes: /\[...slug\]/i,
  id: String(process.env.APP_FULLSTORY_NEW_EXPERIMENT_ID),
  names: {
    control: String(process.env.APP_FULLSTORY_NEW_RECORDED_ID),
    variant1: String(process.env.APP_FULLSTORY_NEW_NOTRECORDED_ID),
  },
  identifier: 'member',
  tenant: 'tk',
  firstTrigger: true,
}

export const APP_FILTERING_EXPERIMENT_ID = String(process.env.APP_FILTERING_EXPERIMENT_ID)
export const APP_FILTERING_CONTROL_ID = String(process.env.APP_FILTERING_CONTROL_ID)
export const APP_FILTERING_VARIANT_ID = String(process.env.APP_FILTERING_VARIANT_ID)

export const filtering: Experiment = {
  name: 'Results count and 0 results prevention',
  routes: /\[...slug\]/i,
  id: APP_FILTERING_EXPERIMENT_ID,
  names: {
    control: APP_FILTERING_CONTROL_ID,
    variant1: APP_FILTERING_VARIANT_ID,
  },
  identifier: 'both',
  tenant: 'tk',
  firstTrigger: true,
}

export const APP_PDP_DESKTOP_CAROUSEL_V2_EXPERIMENT_ID = String(process.env.APP_PDP_DESKTOP_CAROUSEL_V2_EXPERIMENT_ID)
export const APP_PDP_DESKTOP_CAROUSEL_V2_CONTROL_ID = String(process.env.APP_PDP_DESKTOP_CAROUSEL_V2_CONTROL_ID)
export const APP_PDP_DESKTOP_CAROUSEL_V2_VARIANT_ID = String(process.env.APP_PDP_DESKTOP_CAROUSEL_V2_VARIANT_ID)

export const pdpDesktopCarouselV2: Experiment = {
  name: 'PDP Carousel Image Gallery Iteration 2',
  routes: /product/i,
  id: APP_PDP_DESKTOP_CAROUSEL_V2_EXPERIMENT_ID,
  names: {
    control: APP_PDP_DESKTOP_CAROUSEL_V2_CONTROL_ID,
    variant1: APP_PDP_DESKTOP_CAROUSEL_V2_VARIANT_ID,
  },
  identifier: 'guest',
  tenant: 'tk',
  firstTrigger: true,
}

export const REVIEW_PRICE_EXPERIMENT_ID = String(process.env.NEXT_PUBLIC_REVIEW_PRICE_EXPERIMENT_ID)
export const REVIEW_PRICE_CONTROL_ID = String(process.env.NEXT_PUBLIC_REVIEW_PRICE_CONTROL_ID)
export const REVIEW_PRICE_VARIANT_ID = String(process.env.NEXT_PUBLIC_REVIEW_PRICE_VARIANT_ID)

export const reviewPrice: Experiment = {
  name: 'Review Price',
  routes: /review/i,
  id: REVIEW_PRICE_EXPERIMENT_ID,
  names: {
    control: REVIEW_PRICE_CONTROL_ID,
    variant1: REVIEW_PRICE_VARIANT_ID,
  },
  identifier: 'guest',
  tenant: 'tk',
  firstTrigger: true,
}

export const APP_PRICING_FILTER_POSITION_EXPERIMENT_ID = String(process.env.APP_PRICING_FILTER_POSITION_EXPERIMENT_ID)
export const APP_PRICING_FILTER_POSITION_CONTROL_ID = String(process.env.APP_PRICING_FILTER_POSITION_CONTROL_ID)
export const APP_PRICING_FILTER_POSITION_VARIANT_ID = String(process.env.APP_PRICING_FILTER_POSITION_VARIANT_ID)

export const pricingFilterPosition: Experiment = {
  name: 'Change Pricing Filter Position',
  routes: /\[...slug\]/i,
  id: APP_PRICING_FILTER_POSITION_EXPERIMENT_ID,
  names: {
    control: APP_PRICING_FILTER_POSITION_CONTROL_ID,
    variant1: APP_PRICING_FILTER_POSITION_VARIANT_ID,
  },
  identifier: 'guest',
  tenant: 'tk',
  firstTrigger: true,
}

export const APP_UPSELL_CART_EXPERIMENT_ID = String(process.env.APP_UPSELL_CART_EXPERIMENT_ID)
export const APP_UPSELL_CART_CONTROL_ID = String(process.env.APP_UPSELL_CART_CONTROL_ID)
export const APP_UPSELL_CART_VARIANT1_ID = String(process.env.APP_UPSELL_CART_VARIANT1_ID)
export const APP_UPSELL_CART_VARIANT2_ID = String(process.env.APP_UPSELL_CART_VARIANT2_ID)

export const upsellCart: Experiment = {
  name: 'Update Envelope and Liner Upsell prompts in Cart',
  routes: /cart/i,
  id: APP_UPSELL_CART_EXPERIMENT_ID,
  names: {
    control: APP_UPSELL_CART_CONTROL_ID,
    variant1: APP_UPSELL_CART_VARIANT1_ID,
    variant2: APP_UPSELL_CART_VARIANT2_ID,
  },
  identifier: 'guest',
  tenant: 'tk',
  firstTrigger: true,
}
